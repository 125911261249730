import React from 'react';
import './App.css';
import './index.css';

export const Stock: React.FC = () => {
    return (
        <>
            <header className="header">
                <p>
                    Here is a Quick Table of our Stock with the prices:
                </p>
                <table>
                        <tbody>
                        <tr>
                            <th>Item</th>
                            <th>Price</th>
                        </tr>
                        <tr>
                            <td>Roblox Account</td>
                            <td>$15.00</td>
                        </tr>

                        </tbody>
                </table>
            </header>
        </>
    );
}
