import React from 'react';

export const About: React.FC = () => {
    return (
        <div>
            <header className="header">
                <p>
                    <center>
                    You've jumped onto the about page.
                    This is a Project by Faelixyz and someone other who wants to stay anonymous. We're selling MM2 Stuff.<br></br>
                    <a href={'https://discord.gg/6CjG3yV8Av'}>Discord</a><br></br>
                    <a href={'/'}>Homepage</a><br></br>
                    <a href={'/stock'}>Stock</a></center>
                </p>
            </header>
        </div>
    );
}
