import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Homepage } from './App';
import { About} from './about';
import { Stock } from './stock';
export const Router: React.FC = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={ <Homepage /> }/>
                <Route path="/about" element={ <About /> }/>
                <Route path="/stock" element={ <Stock /> }/>
            </Routes>
        </BrowserRouter>
    )
}