import React from 'react';
import './App.css';
import './index.css';
import './App.css';

export const Homepage: React.FC = () => {
  return (
    <>
        <header className="header">
            <p>
                <img src={"https://mm2shoply.github.io/shoplyicon.png"} alt={"Shoply Logo"} width={"100px"} height={"100px"}/>
            Welcome to Shoply!
            Choose any suffix site here.
            </p>
            <a
                className="App-link"
                href="/about"
                target="_blank"
                rel="noopener noreferrer">
                About
            </a>

            <a
                className="App-link"
                href="/stock"
                target="_blank"
                rel="noopener noreferrer">
                Stock
            </a>

            <a
                className="App-link"
                href="https://discord.gg/6CjG3yV8Av"
                target="_blank"
                rel="noopener noreferrer">
                Discord
            </a>
        </header>
    </>
  );
}
